import "./boss.css";

const Boss = () => {

  return (
    <section className="boss">
      <h1>Im the boss</h1>
    </section>
  );
};

export default Boss;
